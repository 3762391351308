import render from "./src/render"

// facebook app browser returns incorrect window.innerWidth values.
// for this case, we will need to read the width via window.screen.width
// the rest of the cases will read the width via window.innerWidth
function isFacebookApp() {
  var ua = navigator.userAgent || navigator.vendor || window.opera
  return ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1
}

// get window width before document ready, some plugins can rewrite the window.width value :D
window.flexWidth = isFacebookApp() ? window.screen.width : window.innerWidth
window.currentFlexViewport = window.flexWidth > 767 ? "desktop" : "mobile"
$(document).ready(() => render())
