export default function customizeFlothemesBlocks() {
  // start: append style tags to head for the https://flothemes.com/ site
  let flexStyleTags = $(".flex-style-tag")
  if (flexStyleTags.length) {
    flexStyleTags.each(function() {
      let template = $(this)
      let style = template.html()
      $("head").append(style)
      template.remove()
    })
  }
  // end: append style tags to head for the https://flothemes.com/ site

  // FLOTHEMES CUSTOM SCRIPTS HOMEPAGE MAY 2019
  let flexThemeOverlays = $(".themes-custom-overlay")
  if (flexThemeOverlays.length) {
    flexThemeOverlays.on("mouseenter", e => {
      let classes = $(e.target)
        .parents(".flex-block__element--shape")
        .attr("class")
      classes = classes.split(" ")
      let lastClass = classes[classes.length - 1]
      let index = lastClass.split("-")
      index = index[index.length - 1]
      let relatedButton = $(".themes-custom-button-" + index)
      $(e.target)
        .parents(".flex-block__element--shape")
        .addClass("flex-visible")
      relatedButton.addClass("flex-visible")
    })
    flexThemeOverlays.on("mouseleave", e => {
      let classes = $(e.target)
        .parents(".flex-block__element--shape")
        .attr("class")
      classes = classes.split(" ")
      let lastClass = classes[classes.length - 2]
      let index = lastClass.split("-")
      index = index[index.length - 1]
      let relatedButton = $(".themes-custom-button-" + index)
      $(e.target)
        .parents(".flex-block__element--shape")
        .removeClass("flex-visible")
      relatedButton.removeClass("flex-visible")
    })
  }

  let flexServicesLink = $(".services-overlay-link")
  if (flexServicesLink.length) {
    flexServicesLink.on("mouseenter", e => {
      let classes = $(e.target)
        .parents(".flex-block__element--shape")
        .attr("class")

      classes = classes.split(" ")
      let lastClass = classes[classes.length - 1]
      let index = lastClass.split("-")
      index = index[index.length - 1]

      let bottomText = $(".services-bottom-text-" + index)
      let bottomLine = $(".services-bottom-line-" + index)
      let customBg = $(".services-custom-bg-" + index)
      let customTitle = $(".services-custom-title-" + index)
      let customText = $(".services-custom-text-" + index)

      bottomText.addClass("flex-services-visible")
      bottomLine.addClass("flex-services-visible")
      customBg.addClass("flex-services-visible")
      customTitle.addClass("flex-services-visible")
      customText.addClass("flex-services-visible")

      $(e.target)
        .parents(".flex-block__element--shape")
        .addClass("flex-services-visible")
    })

    flexServicesLink.on("mouseleave", e => {
      let classes = $(e.target)
        .parents(".flex-block__element--shape")
        .attr("class")
      classes = classes.split(" ")
      let lastClass = classes[classes.length - 2]
      let index = lastClass.split("-")
      index = index[index.length - 1]

      let bottomText = $(".services-bottom-text-" + index)
      let bottomLine = $(".services-bottom-line-" + index)
      let customBg = $(".services-custom-bg-" + index)
      let customTitle = $(".services-custom-title-" + index)
      let customText = $(".services-custom-text-" + index)

      bottomText.removeClass("flex-services-visible")
      bottomLine.removeClass("flex-services-visible")
      customBg.removeClass("flex-services-visible")
      customTitle.removeClass("flex-services-visible")
      customText.removeClass("flex-services-visible")

      $(e.target)
        .parents(".flex-block__element--shape")
        .removeClass("flex-services-visible")
    })
  }
}
