export default function registerBlock($el, id) {
  let renderData = {}
  if ($el.attr("data-flex-desktop")) {
    renderData.desktop = $el.attr("data-flex-desktop")
    $el.removeAttr("data-flex-desktop")
  }
  if ($el.attr("data-flex-mobile")) {
    renderData.mobile = $el.attr("data-flex-mobile")
    $el.removeAttr("data-flex-mobile")
  }

  if (!window.flexBlocksList) {
    window.flexBlocksList = {[id]: renderData}
  } else {
    window.flexBlocksList[id] = renderData
  }
}
