import _ from "lodash"

import lazyload from "./block/lazyload"
import renderBlock from "./block/renderBlock.js"

const b = "flex-block"
const dotb = `.${b}`

const shouldChangeViewport = screenWidth => {
  const newViewport = screenWidth > 767 ? "desktop" : "mobile"

  const shouldChange = newViewport !== window.currentFlexViewport
  if (shouldChange) window.currentFlexViewport = newViewport

  return shouldChange
}

const reRenderAll = width => {
  const $flexBlocks = $(dotb)
  $flexBlocks.each((index, block) => {
    const $el = $(block)
    const id = $el.parents(".flo-block").attr("data-id")
    renderBlock($el, id, {width})
  })
  lazyload()
}

const getFs = () =>
  document.fullscreenElement ||
  document.webkitFullscreenElement ||
  document.mozFullScreenElement

const flexHandleResize = e => {
  const width = window.innerWidth
  if (shouldChangeViewport(width)) {
    const fs = getFs()
    if (!fs) {
      reRenderAll(width)
    } else {
      const eTypes = [
        "fullscreenchange",
        "webkitfullscreenchange",
        "mozfullscreenchange"
      ]
      const handleFsChange = e => {
        reRenderAll(width)
        eTypes.forEach(eType =>
          document.removeEventListener(eType, handleFsChange, false)
        )
      }

      eTypes.forEach(eType =>
        document.addEventListener(eType, handleFsChange, false)
      )
    }
  }
}

const handleVideoClick = e => {
  let $element = $(e.target).parents(`${dotb}__element`)
  let $iframe = $element.find("iframe")
  let $videoFile = $element.find("video")

  let $videoToStart = null
  if ($iframe.length) {
    $videoToStart = $iframe
  } else if ($videoFile.length) {
    $videoToStart = $videoFile
  }

  if (
    $videoToStart &&
    $videoToStart.attr("data-src") &&
    !$videoToStart.attr("src")
  ) {
    $(e.target).fadeOut("250", () => {
      $(e.target).remove()
      $videoToStart.attr("src", $videoToStart.attr("data-src"))
      $videoToStart.removeAttr("data-src")
      $element.addClass(`${b}__video-started`)
    })
  }
}

export default function registerListeners() {
  window.addEventListener("resize", _.throttle(() => flexHandleResize(), 150))

  const playButtons = `${dotb}__video--has-image ${dotb}__play-button`
  $("body").on("click", playButtons, handleVideoClick)

  $("body").on("click", ".flex-no-controls", function() {
    let vid = this
    vid.paused ? vid.play() : vid.pause()
  })
}
