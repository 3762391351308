import registerListeners from "./listeners"
import lazyload from "./block/lazyload"
import renderBlock from "./block/renderBlock"
import registerBlock from "./block/registerBlock"

import customizeFlothemesBlocks from "./customizeFlothemesBlocks" // scripts related to flothemes.com website

export default function render() {
  const $blocks = $(".flex-block")
  $blocks.each((index, block) => {
    const $el = $(block)
    const id = $el.parents(".flo-block").attr("data-id")

    // when page loads, each block HTML markup (both desktop and mobile) need to be saved to a window variable
    // window.flexBlocksList = {}
    registerBlock($el, id)

    // pull according block HTML markup and add it to the page
    renderBlock($el, id)
  })

  // init lazyload for images and videos added by the "renderBlock" function
  lazyload()
  // all event handlers are declared in the function below: "registerListeners"
  registerListeners()

  customizeFlothemesBlocks()
}
