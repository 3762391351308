import parallax from "jquery-parallax.js" // eslint-disable-line

export function renderVideos($parent) {
  // if self hosted video has no controls, play/pause by clicking on the whole video DOM element
  let selfHostedVideos = $parent.find("video")
  selfHostedVideos.each((i, video) => {
    if (!$(video).attr("controls")) $(video).addClass("flex-no-controls")
  })
}

export function renderParallax($parent) {
  const dotb = ".flex-block"
  $parent.find(`${dotb}__with-parallax ${dotb}__bg-image`).parallax({
    mirrorContainer: $parent,
    overScrollFix: true
  })
}
