import viewportChecker from "jquery-viewport-checker" // eslint-disable-line

const lazyLoadImage = entry => {
  let $image = entry.find("img")
  if ($image.length) {
    const image = $image[0]
    if (image.hasAttribute("data-src")) {
      image.src = image.dataset.src
      image.removeAttribute("data-src")
    }
    if (image.hasAttribute("data-srcset")) {
      image.srcset = image.dataset.srcset
      image.removeAttribute("data-srcset")
    }
  }
}

const lazyLoadVideo = entry => {
  let $iframe = entry.find("iframe")
  let $videoFile = entry.find("video")

  let $video = null
  if ($iframe.length) {
    $video = $iframe
  } else if ($videoFile.length) {
    $video = $videoFile
  }
  if ($video && $video.attr("data-src") && !$video.attr("src")) {
    $video.attr("src", $video.attr("data-src"))
    $video.removeAttr("data-src")
  }
}

export default function lazyload() {
  const b = "flex-block"
  const dotb = `.${b}`
  const $targets = $(`${dotb}__element--image, ${dotb}__video--has-image, ${dotb}__video--no-image`) // prettier-ignore

  const $imgs = $(".flex-img-tag")
  $imgs.on("load", e => {
    e.currentTarget.style.opacity = 1
    e.currentTarget.style.filter = "blur(0px)"
  })

  $targets.viewportChecker({
    classToAdd: "loaded", // Class to add to the elements when they are visible,
    offset: "-30%", // The offset of the elements (let them appear earlier or later). This can also be percentage based by adding a '%' at the end
    invertBottomOffset: true, // Add the offset as a negative number to the element's bottom
    repeat: false, // Add the possibility to remove the class if the elements are not visible,
    callbackFunction: entry => {
      if (entry.hasClass(`${b}__video--no-image`)) {
        lazyLoadVideo(entry)
      } else {
        lazyLoadImage(entry)
      }
    }
  })
}
